import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS, withFetch } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
// import {
//     RecaptchaModule,
//     RecaptchaV3Module,
//     RECAPTCHA_SETTINGS,
//     RecaptchaSettings,
//     RECAPTCHA_V3_SITE_KEY
// } from 'ng-recaptcha';
import { provideToastr } from 'ngx-toastr';
import { ApiInterceptor } from './shared/interceptors/api.interceptor';
import { ErrorService } from './shared/services/error.service';
import { constants } from './utils/constants';
import { TranslatePipe, PrefixLangPipe, PrefixSubscriptionPipe, AddressPipe } from './utils/pipes';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
    providers: [
        // angular
        // withFetch() conflicts w/ upload progress
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        // plugins
        provideToastr({
            timeOut: 4000,
            extendedTimeOut: 4000,
            positionClass: 'toast-top-center',
            closeButton: false,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        // recaptcha
        // importProvidersFrom(RecaptchaModule),
        // importProvidersFrom(RecaptchaV3Module),
        // {
        //     provide: RECAPTCHA_SETTINGS,
        //     useValue: {
        //         siteKey: constants.recaptchaV2Sitekey
        //     } as RecaptchaSettings
        // },
        // { provide: RECAPTCHA_V3_SITE_KEY, useValue: constants.recaptchaV3Sitekey },
        // interceptors
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        // error handler
        { provide: ErrorHandler, useClass: ErrorService },
        // pipes
        TranslatePipe,
        PrefixLangPipe,
        PrefixSubscriptionPipe,
        AddressPipe
    ]
};
